<template>
  <el-form-item :label="currentField.label">
    <el-row>
      <el-col>
        <el-upload
          accept="image/*"
          class="avatar-uploader"
          name="assessment"
          list-type="picture-card"
          :show-file-list="false"
          :action="currentField.uploadURL"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-change="handleUploadChange"
          :on-success="handleUploadSuccess"
          :before-remove="beforeRemove"
          :file-list="fileList"
          :multiple="false"
          :limit="3"
          :on-exceed="handleExceed"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else slot="default" class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">
            jpg/png files with a size less than 10MB
          </div>
          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
          <!-- <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
          </div> -->
        </el-upload>
      </el-col>
    </el-row>
  </el-form-item>
</template>
<script>
export default {
  name: 'Upload',
  props: ['currentField'],
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imageUrl: '',
      fileList: []
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.imageUrl = ''
      this.fileList = []
      // console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 1, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`
      )
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`)
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1)
    },
    handleUploadSuccess(resp, file) {
      console.log('upload success')
      if ((resp, resp.status && resp.status == 'success' && resp.result)) {
        this.currentField.value = resp.result.url_path
        // this.imageUrl = resp.result.url
        this.imageUrl = URL.createObjectURL(file.raw)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
