<template>
  <el-form-item :label="currentField.label">
    <el-row>
      <el-col>
        <el-checkbox-group v-model="checkList">
          <el-row>
            <el-col>
              <el-checkbox
                :span="24"
                v-for="item in currentField.options"
                :key="item.optionValue"
                :label="item.optionLabel"
                :disabled="item.disabled"
              >
                {{ item.optionLabel }}
                <el-input v-model="item.optionData"></el-input>
              </el-checkbox>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </el-col>
    </el-row>
  </el-form-item>
</template>

<script>
import fetchData from '@/api/fetch-data'
export default {
  name: 'CheckboxText',
  props: ['currentField'],
  data() {
    return {
      checkList: [0]
    }
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl
      let valueField = this.currentField.valueField
      let labelField = this.currentField.labelField
      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField)
      promise.then((data) => {
        this.currentField.options = data
      })
    }
  }
}
</script>

<style>
.el-checkbox-group {
  font-size: 14px;
}
</style>
