<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>ฟอร์มการประเมิน</v-subheader>
        </v-col>
        <v-col cols="12">
          <el-tabs v-model="activeName">
            <el-tab-pane label="ออกแบบฟอร์ม" name="Design">
              <Design />
            </el-tab-pane>
            <el-tab-pane label="ดูตัวอย่าง" name="Preview"
              ><Preview
            /></el-tab-pane>
          </el-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Design from '@/components/form_elements/Design'
import Preview from '@/components/form_elements/Preview'

export default {
  name: 'TabRouter',
  data() {
    return {
      activeName: 'Design'
    }
  },
  components: {
    Design,
    Preview
  }
}
</script>
