<template>
  <div class="el-tabs__inner">
    <el-form
      :model="fieldProperties"
      :rules="rules"
      :label-position="labelPosition"
      ref="fieldProperties"
    >
      <el-row>
        <el-col>
          <el-form-item
            label="Field Name"
            v-show="activeField.hasOwnProperty('name')"
          >
            <el-input v-model="activeField.name">{{
              activeField.name
            }}</el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Label Name"
            v-show="activeField.hasOwnProperty('label')"
          >
            <el-input v-model="activeField.label">{{
              activeField.label
            }}</el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Only Carousel -->
      <el-form-item
        label="Height - px"
        v-show="
          activeField.hasOwnProperty('fieldType') &&
            activeField['fieldType'] == 'Carousel'
        "
      >
        <el-input-number
          v-model="activeField.controlHeight"
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <!-- Show only when 'isPlacehodlerVisible' key exist -->
      <el-form-item
        label="Placeholder"
        v-show="
          activeField.hasOwnProperty('isPlaceholderVisible') &&
            activeField['isPlaceholderVisible'] == true
        "
      >
        <el-row>
          <el-col :span="5">
            <el-switch v-model="activeField.isPlaceholderVisible"></el-switch>
          </el-col>
          <el-col :span="19">
            <el-input
              v-show="activeField.isPlaceholderVisible"
              v-model="activeField.placeholder"
            >
              {{ activeField.placeholder }}
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- isRequired -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Required field?"
            v-show="activeField.hasOwnProperty('isRequired')"
          >
            <el-switch v-model="activeField.isRequired"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Layout - Max value is 24"
            v-show="activeField.hasOwnProperty('span')"
          >
            <el-input-number
              v-model="activeField.span"
              :min="1"
              :max="24"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Min & Max  -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Min"
            v-if="
              activeField.hasOwnProperty('min') && activeField.min != undefined
            "
          >
            <el-input-number
              v-model="activeField.min"
              :min="1"
              :max="20"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Max"
            v-if="
              activeField.hasOwnProperty('max') && activeField.max != undefined
            "
          >
            <el-input-number
              v-model="activeField.max"
              :min="1"
              :max="20"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        label="Button text"
        v-show="activeField.hasOwnProperty('buttonText')"
      >
        <el-input v-model="activeField.buttonText">
          {{ activeField.buttonText }}
        </el-input>
      </el-form-item>
      <!-- Button type -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Button Color"
            v-show="activeField.hasOwnProperty('buttonType')"
          >
            <el-select v-model="activeField.buttonType" placeholder="Select">
              <el-option
                v-for="item in buttonType"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- image Fit -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Image fit"
            v-show="activeField.hasOwnProperty('imageFit')"
          >
            <el-select v-model="activeField.imageFit" placeholder="Select">
              <el-option
                v-for="item in imageFit"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- Text & Backgroud color -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Text Color"
            v-show="activeField.hasOwnProperty('textColor')"
          >
            <el-color-picker v-model="activeField.textColor"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Background Color"
            v-show="activeField.hasOwnProperty('backgroundColor')"
          >
            <el-color-picker
              v-model="activeField.backgroundColor"
            ></el-color-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Show only when 'isPlacehodlerVisible' key exist -->
      <el-form-item
        label="Detail Label"
        v-show="activeField.hasOwnProperty('detailLabel')"
      >
        <el-row>
          <el-col>
            <el-input v-model="activeField.detailLabel">
              {{ activeField.detailLabel }}
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        label="Code view"
        v-show="activeField.hasOwnProperty('fieldText')"
      >
        <el-input v-model="activeField.fieldText" type="textarea" :rows="10">
          {{ activeField.fieldText }}
        </el-input>
      </el-form-item>

      <!-- <el-form-item label="Helpblock" v-show="activeField.hasOwnProperty('isHelpBlockVisible')">
      <el-switch v-model="activeField.isHelpBlockVisible"></el-switch>
      <el-input v-show="activeField.isHelpBlockVisible" v-model="activeField.helpBlockText">
        {{activeField.helpBlockText}}
      </el-input>
    </el-form-item> -->
      <el-row>
        <el-col :span="12">
          <el-form-item
            label="Active Text"
            v-show="activeField.hasOwnProperty('activeText')"
          >
            <el-input v-model="activeField.activeText">{{
              activeField.activeText
            }}</el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Inactive Text"
            v-show="activeField.hasOwnProperty('inActiveText')"
          >
            <el-input v-model="activeField.inActiveText">{{
              activeField.inActiveText
            }}</el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item
        label="uploadURL"
        v-show="activeField.hasOwnProperty('uploadURL')"
      >
        <el-input v-model="activeField.uploadURL">{{
          activeField.uploadURL
        }}</el-input>
      </el-form-item> -->
      <!-- Items -->
      <!-- Skip section  -->
      <el-row v-if="activeField.fieldType === 'RadioButton'">
        <el-col :span="12">
          <el-form-item label="Skip?">
            <el-switch v-model="activeField.isSkip"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-select v-model="activeField.skipTo" placeholder="หัวข้อ">
            <el-option
              v-for="item in sections"
              :key="item.title"
              :value="item.title"
              :label="item.title"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- Options -->
      <el-form-item label="Items" v-show="activeField.hasOwnProperty('items')">
        <li
          v-for="(item, index) in activeField.items"
          :key="index"
          class="properties__optionslist"
        >
          <el-row :gutter="5">
            <el-col :span="20">
              <el-input v-model="item.url">{{ item.url }}</el-input>
            </el-col>
            <el-col :span="4">
              <el-button
                @click="deleteOption(activeField.items, index)"
                v-show="activeField.items.length > 1"
              >
                <i class="el-icon-error"></i>
              </el-button>
            </el-col>
          </el-row>
        </li>
        <el-button type="text" @click="addItem(activeField.items)">
          <i class="el-icon-plus"></i>
          Add more
        </el-button>
      </el-form-item>
      <!-- imageOptions & isFromUrl -->
      <el-form-item
        label="ImageOptions"
        v-show="activeField.hasOwnProperty('imageOptions')"
        v-if="!activeField.isFromUrl && activeField.imageOptions"
      >
        <ul class="properties__optionsul">
          <li class="properties__optionslist">
            <el-row :gutter="2">
              <el-col :span="4">
                Image
              </el-col>
              <el-col :span="8">
                Label
              </el-col>
              <el-col :span="8">
                Value
              </el-col>
              <el-col :span="4"> </el-col>
            </el-row>
          </li>
          <li
            v-for="(item, index) in activeField.imageOptions"
            :key="index"
            class="properties__optionslist"
          >
            <el-row :gutter="2">
              <el-col :span="4">
                <el-upload
                  name="form"
                  :data="{ index }"
                  :action="activeField.uploadURL"
                  :limit="1"
                  :multiple="false"
                  list-type="picture-card"
                  style="width:50px"
                  :show-file-list="false"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-change="handleUploadChange"
                  :on-success="handleUploadSuccess"
                >
                  <!-- <i class="el-icon-plus"></i> -->
                  <img
                    v-if="item.optionUrl"
                    :src="item.optionUrl"
                    class="buttonImage"
                  />
                  <i v-else class="el-icon-plus buttonImage-uploader-icon"></i>
                </el-upload>
              </el-col>
              <el-col :span="8">
                <el-input v-model="item.optionLabel">{{
                  item.optionLabel
                }}</el-input>
              </el-col>
              <el-col :span="8">
                <el-input v-model="item.optionValue">{{
                  item.optionValue
                }}</el-input>
              </el-col>
              <el-col :span="4">
                <el-button
                  @click="deleteOption(activeField.imageOptions, index)"
                  v-show="activeField.imageOptions.length > 1"
                >
                  <i class="el-icon-error"></i>
                </el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
        <el-button
          type="text"
          @click="addImageOption(activeField.imageOptions)"
        >
          <i class="el-icon-plus"></i>
          Add more
        </el-button>
      </el-form-item>
      <el-form-item
        label="Options"
        v-if="!activeField.isFromUrl && activeField.options"
      >
        <ul class="properties__optionsul">
          <li class="properties__optionslist">
            <el-row :gutter="5">
              <el-col :span="10">
                Label
              </el-col>
              <el-col :span="10">
                Value
              </el-col>
              <el-col :span="4"> </el-col>
            </el-row>
          </li>
          <li
            v-for="(item, index) in activeField.options"
            :key="index"
            class="properties__optionslist"
          >
            <el-row :gutter="5">
              <el-col :span="10">
                <el-input v-model="item.optionLabel">{{
                  item.optionLabel
                }}</el-input>
              </el-col>
              <el-col :span="10">
                <el-input v-model="item.optionValue">{{
                  item.optionValue
                }}</el-input>
              </el-col>
              <el-col :span="4">
                <el-button
                  @click="deleteOption(activeField.options, index)"
                  v-show="activeField.options.length > 1"
                >
                  <i class="el-icon-error"></i>
                </el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
        <el-button type="text" @click="addOption(activeField.options)">
          <i class="el-icon-plus"></i>
          Add more
        </el-button>
      </el-form-item>

      <el-form-item
        label="Table Columns"
        v-show="activeField.fieldType === 'TableComponent'"
      >
        <ul class="properties__optionsul">
          <li class="properties__optionslist">
            <el-row :gutter="5">
              <el-col :span="7">
                Prop
              </el-col>
              <el-col :span="7">
                Label
              </el-col>
              <el-col :span="7">
                Width
              </el-col>
              <el-col :span="3"> </el-col>
            </el-row>
          </li>
          <li
            v-for="(column, index) in activeField.tableColumns"
            :key="index"
            class="properties__optionslist"
          >
            <el-row :gutter="5">
              <el-col :span="7">
                <el-input v-model="column.prop">{{ column.prop }}</el-input>
              </el-col>
              <el-col :span="7">
                <el-input v-model="column.label">{{ column.label }}</el-input>
              </el-col>
              <el-col :span="7">
                <el-input @change="columnWidth" v-model="column.width">{{
                  column.width
                }}</el-input>
              </el-col>
              <el-col :span="3">
                <el-button
                  @click="
                    deleteColumn(activeField.tableColumns, index, column.prop)
                  "
                  v-show="activeField.tableColumns.length > 1"
                >
                  <i class="el-icon-error"></i>
                </el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
        <el-button type="text" @click="addColumn(activeField.tableColumns)">
          <i class="el-icon-plus"></i>
          Add more
        </el-button>
      </el-form-item>

      <el-form-item
        label="Html Content"
        v-show="activeField.hasOwnProperty('htmlContent')"
      >
        <el-input
          :rows="10"
          type="textarea"
          v-model="activeField.htmlContent"
          >{{ activeField.htmlContent }}</el-input
        >
      </el-form-item>

      <el-button
        v-show="activeField.hasOwnProperty('advancedOptions')"
        @click="advancedPropsVisible = true"
        style="width: 100%;"
        type="success"
      >
        Advanced Options
      </el-button>
      <el-dialog
        :close-on-click-modal="false"
        title="Advanced Options"
        :visible.sync="advancedPropsVisible"
      >
        <el-form ref="OptionsForm" :rules="dialogRules">
          <rating-advanced-props
            v-if="activeField.fieldType === 'Rating'"
          ></rating-advanced-props>
          <text-input-advanced-props
            v-if="activeField.fieldType === 'TextInput'"
          ></text-input-advanced-props>
          <html-advanced-props
            v-if="activeField.fieldType === 'HtmlComponent'"
          ></html-advanced-props>
          <number-input-advanced-props
            v-if="activeField.fieldType === 'NumberInput'"
          ></number-input-advanced-props>
          <select-list-advanced-props
            v-if="activeField.fieldType === 'SelectList'"
          ></select-list-advanced-props>
          <options-advanced-props
            v-if="
              activeField.fieldType === 'RadioButton' ||
                activeField.fieldType === 'Checkbox'
            "
          ></options-advanced-props>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmForm">Confirm</el-button>
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import RatingAdvancedProps from './RatingAdvancedProps'
import TextInputAdvancedProps from './TextInputAdvancedProps.vue'
import HtmlAdvancedProps from './HtmlAdvancedProps.vue'
import NumberInputAdvancedProps from './NumberInputAdvancedProps.vue'
import OptionsAdvancedProps from './OptionsAdvancedProps.vue'
import SelectListAdvancedProps from './SelectListAdvancedProps.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Properties',
  components: {
    RatingAdvancedProps,
    TextInputAdvancedProps,
    HtmlAdvancedProps,
    NumberInputAdvancedProps,
    OptionsAdvancedProps,
    SelectListAdvancedProps
  },
  // store: ['activeField'], // Get the form data from Home
  data() {
    return {
      labelPosition: 'left',
      fieldProperties: {},
      fileLists: this.getFileList(),
      rules: {},
      advancedPropsVisible: false,
      dialogRules: {
        dataUrl: [
          { required: true, message: 'Please input url', trigger: 'change' }
        ]
      },
      buttonType: [
        { value: 'primary' },
        { value: 'success' },
        { value: 'warning' },
        { value: 'danger' },
        { value: 'info' },
        { value: 'text' }
      ],
      imageFit: [
        { value: 'fill' },
        { value: 'contain' },
        { value: 'cover' },
        { value: 'none' },
        { value: 'scale-down' }
      ]
    }
  },
  computed: {
    activeField: {
      get() {
        return this.$store.state.formElements.activeField
      },
      set(value) {
        this.$store.commit('SET_ACTIVE_FIELD', value)
      }
    },
    sections: {
      get() {
        return this.$store.state.formElements.forms
      }
    }
  },
  mounted() {
    // console.log('activeField ->', this.activeField)
    // console.log(
    //   "activeField.hasOwnProperty('span') ->",
    //   this.activeField.hasOwnProperty('span')
    // )
  },
  methods: {
    ...mapActions({
      setActiveField: 'setActiveField'
    }),
    handlePictureCardPreview() {},
    handleRemove(file, fileList) {
      console.log(file)
      console.log(fileList)
    },
    handleUploadChange(file, fileList) {
      console.log('upload change')
      console.log(file)
      console.log(fileList)
    },
    handleUploadSuccess(resp) {
      console.log('upload success')
      if ((resp, resp.status && resp.status == 'success' && resp.result)) {
        this.activeField.imageOptions[resp.result.data.index].optionUrl =
          resp.result.url
      } else {
        console.error(resp.message)
      }
    },
    deleteOption(option, index) {
      this.$delete(option, index)
    },
    addOption(option) {
      let count = option.length + 1
      option.push({
        optionLabel: 'Option Label ' + count,
        optionValue: 'Option ' + count
      })
    },
    addImageOption(option) {
      let count = option.length + 1
      option.push({
        optionLabel: 'Label ' + count,
        optionValue: 'value ' + count,
        optionurl: ''
      })
    },
    addItem(item) {
      item.push({
        url: ''
      })
    },
    deleteColumn(column, index, prop) {
      this.$delete(column, index)
      this.activeField.tableDatas.forEach(function(ele) {
        delete ele[prop]
      })
    },
    addColumn(tableColumns) {
      tableColumns.push({
        prop: '',
        label: '',
        width: 180
      })
    },
    columnWidth(value) {
      if (value && isNaN(value)) {
        this.$message.error('Column width should be a number!')
      }
    },
    confirmForm() {
      this.$refs['OptionsForm'].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFileList() {
      if (this.activeField && this.activeField.imageOptions) {
        return this.activeField.imageOptions.map((item) => {
          return { file: item.optionUrl }
        })
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.properties__optionslist {
  margin-bottom: 5px;
  list-style: none;
  list-style-type: none;
}
.properties__optionsul {
  padding: 0;
}
.el-upload--picture-card {
  width: 40px !important;
  height: 40px;
  line-height: 52px;
}
</style>
<style lang="scss">
.el-upload-list--picture-card {
  padding-left: 0px;
}
.buttonImage-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.buttonImage-uploader .el-upload:hover {
  border-color: #409eff;
}
.buttonImage-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.buttonImage {
  width: 40px;
  height: 40px;
  display: block;
}
</style>
