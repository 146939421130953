<template>
  <el-form-item :label="currentField.label">
    <el-col>
      <el-rate
        v-model="currentField.value"
        :colors="currentField.colors"
        :texts="currentField.texts"
        :show-text="currentField.showText"
        :disabled="currentField.disabled"
        :show-score="currentField.showScore"
        :max="currentField.max"
        larger
        :score-template="'{value} ' + currentField.scoreUnit"
      >
      </el-rate>
    </el-col>
  </el-form-item>
</template>
<script>
export default {
  name: 'Rating',
  props: ['currentField'],
  data() {
    return {
      value: null
    }
  },
  mounted() {
    // console.log('currentField', this.currentField)
  }
}
</script>

<style>
.el-rate {
  padding-top: 12px;
  font-size: 25px;
  height: 42px;
}
.el-rate__icon {
  font-size: 40px;
}
</style>
