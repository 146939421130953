<template>
  <el-form-item
    :name="currentField.name"
    :label="currentField.label"
    :label-position="'left'"
  >
    <el-row>
      <el-col>
        <el-radio
          v-model="currentField.value"
          v-for="item in currentField.options"
          :key="item.optionValue"
          :label="item.optionLabel"
          :disabled="item.disabled"
          @change="handleChange"
        >
        </el-radio>
      </el-col>
    </el-row>
  </el-form-item>
</template>

<script>
import fetchData from '@/api/fetch-data'
export default {
  name: 'RadioButton',
  props: ['currentField'],
  data() {
    return {
      radio: '1'
    }
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl
      let valueField = this.currentField.valueField
      let labelField = this.currentField.labelField
      // let nameField = this.currentField.name

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField)
      promise.then((data) => {
        this.currentField.options = data
      })
    }
  },
  methods: {
    handleChange(data) {
      const { isSkip, skipTo, options } = this.currentField
      const chooseIndex = options.findIndex((item) => {
        return item.optionValue == data
      })
      if (isSkip && chooseIndex == 1) {
        this.$emit('onSkipTo', skipTo)
      }
    }
  }
}
</script>
