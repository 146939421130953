<template>
  <el-form-item>
    <el-button :type="currentField.buttonType" class="form__button">{{
      currentField.buttonText
    }}</el-button>
  </el-form-item>
</template>

<script>
export default {
  name: 'Button',
  props: ['currentField']
}
</script>
