<template>
  <div>
    <el-container>
      <el-main class="el-main-left">
        <div class="wrapper--forms">
          <el-form>
            <el-row>
              <el-button
                style="margin-bottom: 10px;"
                type="primary"
                @click="addSection"
                >เพิ่มหัวข้อ</el-button
              >
              <el-button
                style="margin-bottom: 10px;"
                type="success"
                @click="submitForm"
                :loading="loading"
                >บันทึก</el-button
              >
            </el-row>
            <el-row v-if="formsCount == 0">
              <div class="empty-section">กรุณาเพิ่มหัวข้อ</div>
            </el-row>
            <template v-for="(eachFormObj, eachFormIndex) in forms">
              <div :key="`div-${eachFormIndex}`" class="section-block">
                <div class="source">
                  <el-row>
                    <el-col :span="18">
                      <el-input
                        placeholder="Please input section title"
                        :data-index="eachFormIndex"
                        v-model="eachFormObj.title"
                        style="width: 100%;"
                      ></el-input>
                    </el-col>
                    <el-col :span="6">
                      <el-button
                        type="danger"
                        round
                        style="float: right"
                        @click="deleteSection(eachFormIndex, eachFormObj.title)"
                        >ลบหัวข้อ</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="meta">
                  <el-row>
                    <draggable
                      :list="eachFormObj.fields"
                      class="dragArea"
                      :group="{ name: 'formbuilder', pull: false, put: true }"
                      :sort="true"
                      ghost-class="sortable__ghost"
                    >
                      <!-- The form elements starts (on the right) -->
                      <!-- <div> -->
                      <el-col
                        v-for="(field, index) in eachFormObj.fields"
                        :key="index"
                        v-bind="field"
                        :span="field.span"
                        class="form__group"
                        :class="{ 'is--active': field === getActiveField }"
                      >
                        <span class="form__selectedlabel">{{
                          field.fieldType
                        }}</span>

                        <div @click="editElementProperties(field)">
                          <!-- <label class="form__label" v-model="form.label" v-show="form.hasOwnProperty('label')">{{ form.label }}</label> -->
                          <component
                            :is="field.fieldType"
                            :currentField="field"
                            class="form__field"
                          >
                          </component>
                        </div>

                        <!-- Actions list -->
                        <div class="form__actiongroup">
                          <el-button
                            circle
                            size="mini"
                            icon="el-icon-rank"
                            class="form__actionitem--move"
                          ></el-button>

                          <el-button-group class="form__actionlist">
                            <el-button
                              size="mini"
                              icon="el-icon-plus"
                              @click="
                                cloneElement(index, field, eachFormObj.fields)
                              "
                              v-show="!field.isUnique"
                            ></el-button>
                            <el-button
                              size="mini"
                              icon="el-icon-delete"
                              @click="deleteElement(index, eachFormObj.fields)"
                            ></el-button>
                          </el-button-group>
                        </div>
                      </el-col>
                      <!-- </div> -->
                    </draggable>
                  </el-row>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </el-main>
      <el-aside class="wrapper--sidebar" width="40%">
        <el-tabs type="border-card" v-model="activeTabForFields">
          <el-tab-pane name="elements" label="Elements">
            <elements />
          </el-tab-pane>

          <el-tab-pane name="properties" label="Properties">
            <!-- Object.keys($store.activeField).length -->
            <properties></properties>
          </el-tab-pane>
        </el-tabs>
        <!--{{ $store.activeField }}-->
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import { getFormById, saveForm } from '@/api/form'
import { FormBuilder } from '@/components/form_elements/formbuilder'
import { mapState, mapGetters, mapActions } from 'vuex'
import lodash from 'lodash'
const {
  Elements,
  Properties,
  draggable,
  TextInput,
  LongTextInput,
  NumberInput,
  SelectList,
  RadioButton,
  Checkbox,
  TimePicker,
  DatePicker,
  DatetimePicker,
  Rating,
  Button,
  Carousel,
  Upload,
  ElSwitch,
  TableComponent,
  HtmlComponent,
  ImageButton,
  CheckboxText,
  RadioTextButton
} = FormBuilder.$options.components
export default {
  name: 'Design',
  props: ['parentId'],
  data() {
    return {
      loading: false,
      sortElementOptions: FormBuilder.$data.sortElementOptionss
    }
  },
  created() {
    getFormById(this.$route.params.id)
      .then((result) => {
        console.log(result)
        if (result.data) {
          this.setForms(result.data)
          // this.forms = result.data
        } else {
          // this.forms = []
          this.setForms([])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    ...mapState({
      // forms: (state) => state.formElements.forms,
      // getActiveTabForFields: (state) => state.formElements.activeTabForFields
    }),
    ...mapGetters({
      getForms: 'getForms',
      formsCount: 'getFormsCount',
      getActiveField: 'getActiveField'
    }),
    activeTabForFields: {
      get() {
        return this.$store.state.formElements.activeTabForFields
      },
      set(value) {
        this.$store.commit('SET_ACTIVE_TAB', value)
      }
    },
    forms: {
      get() {
        return this.$store.getters.getForms
      },
      set(value) {
        this.$store.commit('SET_FORMS', value)
      }
    },
    pages: {
      get() {
        return this.$store.getters.pages(this.parentId)
      },
      set(value) {
        this.$store.commit('setPages', {
          value,
          parentId: this.parentId
        })
      }
    }
  },
  mounted() {
    console.log(this.$route.params.id)
    console.log('form ->', this.forms)
    console.log('activeField ->', this.getActiveField)
  },
  components: {
    Elements,
    Properties,
    draggable,
    TextInput,
    LongTextInput,
    NumberInput,
    SelectList,
    RadioButton,
    Checkbox,
    TimePicker,
    DatePicker,
    DatetimePicker,
    Rating,
    Button,
    Carousel,
    Upload,
    ElSwitch,
    TableComponent,
    HtmlComponent,
    ImageButton,
    CheckboxText,
    RadioTextButton
  },
  methods: {
    ...mapActions({
      setForms: 'setForms'
    }),
    isParent(page) {
      return page.children.length > 0
    },
    changeFormsTitle() {},
    deleteElement(index, form) {
      this.$store.commit('SET_ACTIVE_FIELD', [])
      this.$store.commit('SET_ACTIVE_TAB', 'elements')
      this.$delete(form, index)
    },
    cloneElement(index, field, form) {
      var cloned = lodash.cloneDeep(field) // clone deep lodash
      form.splice(index, 0, cloned)
    },
    editElementProperties(field) {
      this.$store.commit('SET_ACTIVE_TAB', 'properties')
      this.$store.commit('SET_ACTIVE_FIELD', field)
    },
    updateFormsTitle(title) {
      this.$store.commit('updateFormsTitle', { index: 0, title })
    },
    addSection() {
      const formObj = {
        title: '',
        fields: []
      }
      this.$store.commit('ADD_FORMS', formObj)
    },
    deleteSection(formIndex, formTitle) {
      this.$confirm(
        `Are you sure to delete the section ${formTitle}?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(() => {
        this.$store.commit('DELETE_FORMS', formIndex)
      })
    },
    // Save form
    submitForm() {
      this.loading = true
      saveForm(this.$route.params.id, {
        description: '',
        data: this.forms
      })
        .then(() => {
          this.$notify({
            group: 'notify',
            title: 'บันทึกข้อมูลเรียบร้อย'
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'notify',
            title: 'เกิดข้อผิดพลาด',
            text: err.message
          })
        })
        .finally(() => {
          this.loading = false
        })

      // this.$store.commit('SAVE_SESSION')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.empty-section {
  text-align: center;
  font-size: 40px;
  background: linear-gradient(to bottom, #fff, #409eff);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.dragArea {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  min-height: 10px;
  height: calc(30vh);
  z-index: 2;
}

.el-main-left {
  height: calc(100vh);
}

.form__selectedlabel {
  display: none;
  background: #ecf5ff;
  padding: 3px 5px;
  color: black;
  font-size: 10px;
  position: absolute;
  top: -17px;
  right: 15px;
}

.form__actionitem--move {
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;

  &:active,
  &:focus,
  &:hover {
    border-color: #409eff;
    background: #ecf5ff;
  }
}

.form__actionlist {
  position: absolute;
  margin-top: 10px;
  visibility: hidden;
  z-index: 3;
  right: 0;
  border-radius: 2px;
}

.form__group {
  margin-bottom: 25px;
  border: 1px solid transparent;
  position: relative;

  &:hover {
    border-color: #409eff;

    .form__actionitem--move {
      visibility: visible;
    }
  }

  &.is--active {
    border-color: #409eff;
    background: #ecf5ff;

    .form__actionlist {
      visibility: visible;
    }
    .form__selectedlabel {
      display: inline-block;
    }
  }
}

.section-block {
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.section-block .source {
  padding: 10px;
}

.section-block .meta {
  background-color: #fafafa;
  border-top: 1px solid #eaeefb;
}

.wrapper--sidebar {
  padding: 5px;

  .el-tabs {
    min-height: 450px;
    border-bottom: 0;
  }

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__content {
    padding: 0;
  }

  .el-tabs__inner {
    padding: 5px;
  }

  .el-tabs__nav {
    float: none;

    .el-tabs__item {
      width: 40%;
      text-align: center;
    }
  }

  .el-tabs--border-card {
    border: 1px solid gray;
  }

  .el-form-item__label {
    line-height: 1;
    text-align: left;
  }
}

.el-input-number {
  width: auto !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: auto !important;
}

// Preview
.preview__wrapper {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.el-form-item {
  padding: 10px;
  margin-bottom: 0;
}
</style>
