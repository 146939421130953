<template>
  <el-form-item :label="currentField.label">
    <el-date-picker
      v-model="currentField.value"
      type="date"
      placeholder="Pick a day"
    >
    </el-date-picker>
  </el-form-item>
</template>

<script>
export default {
  name: 'DatePicker',
  props: ['currentField'],
  data() {
    return {
      date: ''
    }
  }
}
</script>
