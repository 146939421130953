import Vue from 'vue'

import draggable from 'vuedraggable'

import TextInput from '@/components/form_elements/FormElementTextInput'
import LongTextInput from '@/components/form_elements/FormElementLongTextInput'
import NumberInput from '@/components/form_elements/FormElementNumberInput'
import SelectList from '@/components/form_elements/FormElementSelectList'
import RadioButton from '@/components/form_elements/FormElementRadioButton'
import Checkbox from '@/components/form_elements/FormElementCheckbox'
import CheckboxText from '@/components/form_elements/FormElementCheckboxText'
import TimePicker from '@/components/form_elements/FormElementTimePicker'
import DatePicker from '@/components/form_elements/FormElementDatePicker'
import DatetimePicker from '@/components/form_elements/FormElementDatetimePicker'
import Rating from '@/components/form_elements/FormElementRating'
import Button from '@/components/form_elements/FormElementButton'
import Carousel from '@/components/form_elements/FormElementCarousel'
import Upload from '@/components/form_elements/FormElementUpload'
import ElSwitch from '@/components/form_elements/FormElementSwitch'
import TableComponent from '@/components/form_elements/FormElementTable'
import HtmlComponent from '@/components/form_elements/FormElementHtml'
import ImageButton from '@/components/form_elements/FormElementImageButton'
import RadioTextButton from '@/components/form_elements/FormElementRadioTextButton'

import Elements from '@/components/form_elements/Elements'
import Properties from '@/components/form_elements/properties/Properties'
import Utils from '@/util'
// import vm from '@/main'
// import lodash from 'lodash'
// import { mapState } from 'vuex'
export const FormBuilder = new Vue({
  components: {
    Elements,
    Properties,
    draggable,
    TextInput,
    LongTextInput,
    NumberInput,
    SelectList,
    RadioButton,
    Checkbox,
    CheckboxText,
    TimePicker,
    DatePicker,
    DatetimePicker,
    Rating,
    Button,
    Carousel,
    Upload,
    ElSwitch,
    TableComponent,
    HtmlComponent,
    ImageButton,
    RadioTextButton
  },
  data() {
    return {
      fields: [
        {
          fieldType: 'TextInput',
          name: '',
          label: 'Text',
          text: 'Text',
          group: 'form', //form group
          isInline: false,
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: true,
          isUnique: false,
          span: 24,
          labelCol: 8,
          labelWidth: 100,
          advancedOptions: true,
          showPassword: false,
          disabled: false,
          clearable: false,
          prepend: '',
          append: '',
          maxlength: 10,
          showWordLimit: false
        },
        {
          fieldType: 'LongTextInput',
          name: '',
          label: 'Long Text',
          text: 'Long Text',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: true,
          isUnique: false,
          span: 24,
          labelWidth: 100
        },
        {
          fieldType: 'NumberInput',
          name: '',
          label: 'Number',
          text: 'Number',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          advancedOptions: false,
          disabled: false,
          stepStrictly: false,
          step: 1,
          hasMinValue: false,
          min: 1,
          hasMaxValue: false,
          max: 10
        },
        {
          fieldType: 'SelectList',
          name: '',
          label: 'Select',
          text: 'Select',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          dataUrl: '',
          options: [
            {
              optionLabel: 'Option Label 1',
              optionValue: 'Option 1'
            },
            {
              optionLabel: 'Option Label 2',
              optionValue: 'Option 2'
            }
          ],
          advancedOptions: false,
          labelField: 'label',
          valueField: 'value',
          disabled: false,
          clearable: false,
          multiple: false,
          filterable: false,
          remote: false
        },
        {
          fieldType: 'RadioButton',
          name: '',
          label: 'Radio',
          text: 'Radio',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          isSkip: false,
          skipTo: '',
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          options: [
            {
              optionLabel: 'Option Label 1',
              optionValue: 'Option 1'
            },
            {
              optionLabel: 'Option Label 2',
              optionValue: 'Option 2'
            }
          ],
          advancedOptions: true,
          dataUrl: '',
          labelField: 'label',
          valueField: 'value'
        },
        {
          fieldType: 'Checkbox',
          name: '',
          label: 'Checkbox',
          text: 'Checkbox',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          min: 1,
          max: 5,
          options: [
            {
              optionLabel: 'Option Label 1',
              optionValue: 'Option 1'
            },
            {
              optionLabel: 'Option Label 2',
              optionValue: 'Option 2'
            }
          ],
          advancedOptions: true,
          dataUrl: '',
          labelField: 'label',
          valueField: 'value',
          value: []
        },
        {
          fieldType: 'CheckboxText',
          name: '',
          label: 'CheckboxText',
          text: 'CheckboxText(ยกเลิก)',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          min: 1,
          max: 5,
          value: [],
          options: [
            {
              optionLabel: 'Option Label 1',
              optionValue: 'Option 1'
            },
            {
              optionLabel: 'Option Label 2',
              optionValue: 'Option 2'
            }
          ],
          advancedOptions: true,
          dataUrl: '',
          labelField: 'label',
          valueField: 'value'
        },
        // {
        //   fieldType: 'TimePicker',
        //   name: '',
        //   label: 'Time',
        //   text: 'Time',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100
        // },
        // {
        //   fieldType: 'DatePicker',
        //   name: '',
        //   label: 'Date',
        //   text: 'Date',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100
        // },
        // {
        //   fieldType: 'DatetimePicker',
        //   name: '',
        //   label: 'Date time',
        //   text: 'Date-Time',
        //   group: 'form',
        //   isRequired: false,
        //   isHelpBlockVisible: false,
        //   isPlaceholderVisible: false,
        //   isUnique: false,
        //   span: 24,
        //   labelWidth: 100
        // },
        {
          fieldType: 'Rating',
          name: '',
          label: 'Rating',
          text: 'Rating',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          advancedOptions: true,
          rateValue: 0,
          showText: true,
          disabled: false,
          showScore: false,
          scoreUnit: 'points',
          max: 5,
          colors: ['#AAAAAA', '#F7BA2A', '#FF9900'],
          texts: ['oops', 'disappointed', 'normal', 'good', 'great']
        },
        {
          fieldType: 'ElSwitch',
          name: '',
          label: 'Switch',
          text: 'Switch',
          group: 'form',
          isUnique: false,
          span: 24,
          labelWidth: 100,
          activeText: '',
          inActiveText: ''
        },
        {
          fieldType: 'Button',
          name: '',
          text: 'Button',
          group: 'button',
          buttonType: '',
          buttonText: 'Submit your form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: true,
          span: 24,
          labelWidth: 100
        },
        {
          fieldType: 'Carousel',
          name: '',
          text: 'Carousel',
          group: 'static',
          isUnique: false,
          span: 24,
          imageFit: 'contain',
          labelWidth: 100,
          controlHeight: 150,
          itemsNumber: 4,
          items: [
            {
              url: ''
            }
          ]
        },
        {
          fieldType: 'Upload',
          name: '',
          label: 'Upload',
          text: 'UploadFiles',
          group: 'form',
          isUnique: false,
          span: 24,
          uploadURL: Utils.getAPIUrl('/attachment/assessment'),
          value: ''
        },
        // {
        //   fieldType: 'TableComponent',
        //   name: '',
        //   text: 'Table',
        //   group: 'form',
        //   isUnique: false,
        //   span: 24,
        //   tableColumns: [
        //     {
        //       prop: 'date',
        //       label: 'Date',
        //       width: 180
        //     },
        //     {
        //       prop: 'name',
        //       label: 'Name',
        //       width: 180
        //     },
        //     {
        //       prop: 'address',
        //       label: 'Address'
        //     }
        //   ],
        //   tableDatas: [
        //     {
        //       id: 1,
        //       edit: false,
        //       date: '2016-05-03',
        //       name: 'Tom',
        //       address: 'No. 189, Grove St, Los Angeles'
        //     }
        //   ]
        // },
        {
          fieldType: 'HtmlComponent',
          name: '',
          text: 'Html',
          group: 'static',
          isUnique: false,
          span: 24,
          htmlContent: '<h3>Hello World</h3>',
          advancedOptions: ''
        },
        {
          fieldType: 'ImageButton',
          name: '',
          label: 'ImageButton',
          text: 'Image Button',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          min: 1,
          max: 10,
          uploadURL: Utils.getAPIUrl('/attachment/form'),
          imageOptions: [
            {
              optionUrl: ``,
              optionLabel: 'Label 1',
              optionValue: 'Value 1'
            },
            {
              optionUrl: ``,
              optionLabel: 'Label 2',
              optionValue: 'Value 2'
            }
          ],
          advancedOptions: false,
          dataUrl: '',
          labelField: 'label',
          valueField: 'value',
          value: ''
        },
        {
          fieldType: 'RadioTextButton',
          name: '',
          label: 'Radio Text',
          text: 'Radio Text',
          group: 'form',
          isRequired: false,
          isHelpBlockVisible: false,
          isPlaceholderVisible: false,
          isUnique: false,
          span: 24,
          labelWidth: 100,
          isFromUrl: false,
          options: [
            {
              optionLabel: 'Option Label 1',
              optionValue: 'Option 1'
            },
            {
              optionLabel: 'Option Label 2',
              optionValue: 'Option 2'
            }
          ],
          advancedOptions: true,
          dataUrl: '',
          labelField: 'label',
          valueField: 'value',
          detailLabel: 'Detail',
          detailData: '',
          value: '',
          optionData: ''
        }
      ],
      sortElementOptions: {
        group: {
          name: 'formbuilder',
          pull: false,
          put: true
        },
        sort: true
      },

      dropElementOptions: {
        group: {
          name: 'formbuilder',
          pull: 'clone',
          put: false
        },
        sort: false,
        filter: '.is-disabled'
      }
    }
  },
  methods: {
    getValue(fieldType, field) {
      if (fieldType == 'Rating') {
        return { fieldType, label: field.label, value: field.rateValue }
      } else {
        // RadioButton, Rating, CheckboxText, LongTextInput
        return { fieldType, label: field.label, value: field.value }
      }
    }
    // deleteElement(index, form) {
    //   this.$store.commit('SET_ACTIVE_FIELD', [])
    //   this.$store.commit('SET_ACTIVE_TAB', 'elements')
    //   this.$store.commit('DELETE_FORMS', index)
    //   // vm.$store.activeField = []
    //   // vm.$store.activeTabForFields = 'elements'
    //   // vm.$delete(form, index)
    // },
    // cloneElement(index, field, form) {
    //   var cloned = lodash.cloneDeep(field) // clone deep lodash
    //   form.splice(index, 0, cloned)
    // }
    // ,
    // editElementProperties(field) {
    //   // this.$store.commit('DELETE_FORMS', field)
    //   // vm.$store.activeField = field
    //   // vm.$store.activeTabForFields = 'properties'
    //   this.$store.commit('SET_ACTIVE_FIELD', field)
    //   this.$store.commit('SET_ACTIVE_TAB', 'properties')
    // }
  }
})
