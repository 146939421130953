<template>
  <el-form-item
    :name="currentField.name"
    :label="currentField.label"
    :label-position="'left'"
  >
    <el-row>
      <el-col>
        <el-radio
          v-model="currentField.value"
          v-for="item in currentField.options"
          :key="item.optionValue"
          :label="item.optionLabel"
          :disabled="item.disabled"
        >
        </el-radio>
        <el-input
          :placeholder="currentField.detailLabel"
          v-model="currentField.detailData"
        ></el-input>
      </el-col>
    </el-row>
  </el-form-item>
</template>

<script>
import fetchData from '@/api/fetch-data'
export default {
  name: 'RadioTextButton',
  props: ['currentField'],
  data() {
    return {
      radio: '1'
    }
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      let dataUrl = this.currentField.dataUrl
      let valueField = this.currentField.valueField
      let labelField = this.currentField.labelField
      // let nameField = this.currentField.name

      let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField)
      promise.then((data) => {
        this.currentField.options = data
      })
    }
  }
}
</script>
