<template>
  <el-form-item :label="currentField.label">
    <el-row>
      <el-col>
        <el-image
          v-for="item in currentField.imageOptions"
          :key="item.optionValue.trim()"
          :v-model="selectList"
          @click="chooseItem(item)"
          style="width: 100px; height: 100px"
          :class="getActiveClass(item)"
          :src="item.optionUrl"
          :fit="'contain'"
        >
          >
        </el-image>
      </el-col>
    </el-row>
  </el-form-item>
</template>
<script>
// import fetchData from '@/api/fetch-data'
export default {
  name: 'ImageButton',
  props: ['currentField'],
  data() {
    return {
      // isMultiple: false,
      selectList: []
      // radio: '1'
    }
  },
  mounted() {
    if (this.currentField.isFromUrl) {
      // let dataUrl = this.currentField.dataUrl
      // let valueField = this.currentField.valueField
      // let value = this.currentField.value
      // let nameField = this.currentField.name
      // let promise = fetchData.fetchOptionsData(dataUrl, labelField, valueField)
      // promise.then((data) => {
      //   this.currentField.options = data
      // })
    }
  },
  methods: {
    chooseItem(item) {
      if (item.optionValue == undefined) {
        console.error(item)
      }
      const maxItem = this.currentField.max ? this.currentField.max : null
      const itemValue = item.optionValue.trim()
      const indexItem = this.selectList.indexOf(itemValue)
      if (indexItem > -1) {
        this.selectList.splice(indexItem, 1)
      } else if (maxItem && this.selectList.length >= maxItem) {
        this.$notify({
          type: 'warning',
          group: 'notify',
          title: `เลือกได้สูงสุด ${maxItem} รายการ `
        })
        return
      } else {
        this.selectList.push(itemValue)
      }
      this.currentField.value = this.selectList
    },
    getActiveClass(item) {
      return this.selectList.indexOf(item.optionValue.trim()) > -1
        ? 'active'
        : ''
    }
  }
}
</script>
<style scoped>
.el-image {
  padding: 5px;
  cursor: pointer;
}
.active {
  border: solid 2px rgb(56, 150, 75);
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 14px;
}
</style>
